<template>
  <div
    class="requirement-search-chat__group requirement-search-chat__display requirement-search-chat__dialog"
    :class="{ 'requirement-search-chat__display--show': showClass }"
  >
    <div class="requirement-search-chat__group-text-container">
      <h2 class="requirement-search-chat__group-title" v-html="group.name"></h2>
      <p class="requirement-search-chat__dialog-text">
        Vi har fundet {{ group.adCount }}
        {{ group.adCount === 1 ? 'bil' : 'biler' }} af denne type
      </p>
    </div>
    <div class="requirement-search-chat__group-image-container">
      <div class="requirement-search-chat__group-image-container-inner">
        <img
          class="requirement-search-chat__group-image"
          :class="{ 'fallback-image': !group.imageUrl }"
          :src="group.imageUrl"
          @error="
            () => {
              group.imageUrl = null
            }
          "
          width="232"
          height="132"
        />
      </div>
    </div>
    <a
      :href="group.url"
      @click="waitRedirect($event, group.url)"
      class="requirement-search-chat__group-link"
    ></a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showClass: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.showClass = true
    }, this.getTimeout(this.index))
  },
  methods: {
    getTimeout(index) {
      let timeout = 0

      if (index < 3) {
        timeout = 2 * 1600 * 1.6 // 2 questions
      }

      timeout += ((index % 3) + 1) * 500 // our time

      return timeout
    },
    waitRedirect(event, url) {
      event.preventDefault()

      this.closeChatCallback(() => {
        document.location = url
      })
    },
  },
  props: {
    group: {
      default: () => {
        return {}
      },
      type: Object,
    },
    index: { default: 0, type: Number },
    closeChatCallback: { default: null, type: Function },
  },
}
</script>
