<template>
  <div
    class="requirement-search-chat__dialog-mb requirement-search-chat__question-container"
    :class="{
      'requirement-search-chat__display--show': showContainer,
      'requirement-search-chat__display': !noAnimation,
    }"
  >
    <img
      class="requirement-search-chat__question-image"
      src="/static/img/vector/logo-green.svg"
    />
    <div>
      <p
        class="requirement-search-chat__question requirement-search-chat__dialog requirement-search-chat__dialog-text"
        :class="{
          'requirement-search-chat__question-animate--show': showQuestion,
          'requirement-search-chat__question-animate': !noAnimation,
        }"
        v-html="question"
      ></p>
      <div class="typing" v-if="!showQuestion && !noAnimation">
        <div class="typing__dot"></div>
        <div class="typing__dot"></div>
        <div class="typing__dot"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showContainer: false,
      showQuestion: false,
    }
  },
  watch: {
    showContainer() {
      setTimeout(() => {
        this.showQuestion = true
      }, 1700)
    },
  },
  mounted() {
    if (this.noAnimation) {
      this.showContainer = true
      return
    }

    setTimeout(() => {
      this.showContainer = true
    }, this.getTimeout(this.index))
  },
  methods: {
    getTimeout(index) {
      return index * 1600 * 1.6
    },
  },
  props: {
    question: {
      default: '',
      type: String,
    },
    index: { default: 0, type: Number },
    noAnimation: { default: false, type: Boolean },
  },
}
</script>
