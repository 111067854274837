<template>
  <div>
    <RequirementSearchPopup
      v-if="!isChatOpen"
      :open-chat-callback="openChat"
      :showPopupFullSizeProp="showPopupFullSize"
    />
    <RequirementSearchChat
      v-else
      :questions="questions"
      :groups="groups"
      :priorities="priorities"
      :isPrioritiesFinished="isPrioritiesFinished"
      :answer-callback="pushAnswer"
      :close-chat-callback="closeChat"
      :restart-callback="restart"
      :questions-done-callback="questionsDone"
      :priorities-done-callback="prioritiesDone"
    />
  </div>
</template>

<script>
import { app } from '@/main'
import { nextTick } from 'vue'

import RequirementSearchChat from './RequirementSearchChat.vue'
import RequirementSearchPopup from './RequirementSearchPopup.vue'

import { toggleDocumentOverflow } from '@/functions'

import { RequirementSearchFinished, RequirementSearchOpened } from '@/events'

export default {
  methods: {
    pushAnswer(answerObject) {
      answerObject.questionId
      answerObject.answer

      const question = this.questions.find(
        question => question.id === answerObject.questionId
      )

      if (question !== undefined) {
        const answer = question.answers.find(
          answer => answer.value == answerObject.answer.value
        )

        if (answer !== undefined) {
          answer.answered = true
        }

        nextTick(() => {
          app.axios.post('/api/RequirementSearch/SaveProgress', {
            answers: this.getAnswers(),
          })
        })
      }
    },
    getAnswers() {
      const answers = []

      const answeredQuestions = this.questions.filter(
        question =>
          question.answers.find(answer => answer.answered) !== undefined
      )

      answeredQuestions.forEach(answeredQuestion => {
        const answeredObject = {}
        answeredObject.questionId = answeredQuestion.id

        const answer = answeredQuestion.answers.find(answer => answer.answered)
        if (answer !== undefined) {
          answeredObject.value = answer.value
          answeredObject.valueMin = answer.valueMin
          answeredObject.valueMax = answer.valueMax

          answers.push(answeredObject)
        }
      })

      return answers
    },
    resetAnswers() {
      const answeredQuestions = this.questions.filter(
        question =>
          question.answers.find(answer => answer.answered) !== undefined
      )

      answeredQuestions.forEach(answeredQuestion => {
        const answeredObject = {}
        answeredObject.questionId = answeredQuestion.id

        const answer = answeredQuestion.answers.find(answer => answer.answered)
        if (answer !== undefined) {
          answer.answered = undefined
        }
      })
    },
    closeChat(callback) {
      app.axios.post('/api/RequirementSearch/Close').then(() => {
        if (typeof callback === typeof Function) {
          callback()
        }
      })

      if (typeof callback !== typeof Function) {
        this.isChatOpen = false
      }
    },
    openChat() {
      app.axios.post('/api/RequirementSearch/Open')
      this.isChatOpen = true
      RequirementSearchOpened()
    },
    restart() {
      app.axios.post('/api/RequirementSearch/Restart')
      this.resetAnswers()
      this.groups = null
      this.priorities = null
      this.isPrioritiesFinished = false
    },
    finished() {
      app.axios
        .post('/api/RequirementSearch/Finish', {
          priorities: this.priorities,
        })
        .then(resp => {
          this.groups = resp.data.groups
          RequirementSearchFinished()
        })
    },
    questionsDone() {
      app.axios
        .post('/api/RequirementSearch/GetPriorities', {
          answers: this.getAnswers(),
        })
        .then(resp => {
          this.priorities = resp.data
        })
    },
    prioritiesDone() {
      this.finished()

      this.isPrioritiesFinished = true
    },
    goToSearch() {
      let searchResultsContainer = document.getElementsByClassName(
        'search-results-container'
      )[0]
      let requirementSearchPage = document.getElementsByClassName(
        'requirement-search'
      )[0]

      if (searchResultsContainer && requirementSearchPage) {
        window.scrollTo(
          0,
          searchResultsContainer.getBoundingClientRect().top +
            (window.scrollY - 200)
        )
      }
    },
  },
  watch: {
    isChatOpen(newValue) {
      toggleDocumentOverflow(newValue)
    },
  },
  mounted() {
    toggleDocumentOverflow(this.isChatOpen)
    setTimeout(() => {
      this.goToSearch()
    }, 200)
    app.mitt.on('open-requirement-chat', this.openChat)
  },
  data() {
    return {
      isChatOpen: this.chatData.isChatOpen,
      questions: this.chatData.questions,
      groups: this.chatData.groups,
      isPrioritiesFinished: this.chatData.isPrioritiesFinished,
      priorities: this.chatData.priorities,
      showPopupFullSize: this.chatData.showPopupFullSize,
    }
  },
  props: {
    chatData: {
      default: () => {
        return {}
      },
      type: Object,
    },
  },
  components: {
    RequirementSearchChat,
    RequirementSearchPopup,
  },
}
</script>
