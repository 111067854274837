<template>
  <div>
    <RequirementSearchChatGroup
      v-for="(group, index) in groupsToShow"
      :key="index"
      :group="group"
      :index="index"
      :close-chat-callback="closeChatCallback"
    />
    <button
      @click="getMore"
      v-if="this.groupsToShow.length < this.groups.length"
      class="requirement-search-chat__button button button--highlight requirement-search-chat__display"
      :class="{ 'requirement-search-chat__display--show': showGetMore }"
    >
      Se flere
    </button>
  </div>
</template>

<script>
import RequirementSearchChatGroup from './RequirementSearchChatGroup.vue'

export default {
  components: {
    RequirementSearchChatGroup,
  },
  data() {
    return {
      groupsToShow: [],
      firstLoad: true,
      showGetMore: false,
    }
  },
  mounted() {
    this.getMore()

    setTimeout(() => {
      this.showGetMore = true
    }, 2 * 1600 * 1.6 + this.groupsToShow.length * 500) // 2 questions, n groups, then our time
  },
  methods: {
    getMore() {
      this.groupsToShow = this.groups.slice(0, this.groupsToShow.length + 3)

      this.$nextTick(() => {
        this.goToBottomCallback()
      })
    },
  },
  props: {
    groups: {
      default: () => {
        return []
      },
      type: Array,
    },
    closeChatCallback: { default: null, type: Function },
    goToBottomCallback: { default: null, type: Function },
  },
}
</script>
