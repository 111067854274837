<template>
  <div class="requirement-search-chat">
    <div class="requirement-search-chat__top">
      <p class="requirement-search-chat__top-text">Bilguiden</p>

      <div class="requirement-search-chat__top-icon-container">
        <button
          class="requirement-search-chat__top-icon-button"
          @click="restartCallback"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" v-bind:class="'requirement-search-chat__top-icon'" v-bind:width="'21'" v-bind:height="'21'" v-bind:aria-label="'Start forfra'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="currentColor" d="M63.8 102.2c41.1-41.1 95.7-63.7 153.9-63.7S330.4 61 371.5 102.2A216 216 0 01435.2 256v33.1l54.9-54.9a12.8 12.8 0 1118.1 18.1l-76.8 76.8a13 13 0 01-18.2 0l-76.8-76.8a12.8 12.8 0 1118.1-18.1l54.9 54.9V256c0-105.9-86.1-192-192-192s-192 86.1-192 192 86.1 192 192 192c7.1 0 12.8 5.7 12.8 12.8 0 7.1-5.7 12.8-12.8 12.8A217 217 0 010 256c0-58.1 22.6-112.8 63.8-153.8z"/></svg>
        </button>
        <button
          class="requirement-search-chat__top-icon-button"
          @click="closeChatCallback"
        >
          <span
            aria-label="Luk"
            class="requirement-search-chat__top-icon icon--plus icon--rotate"
          ></span>
        </button>
      </div>
      <!--
      <p>
        Spørgsmål
        {{
          answeredQuestions.length + 1 > questions.length
            ? questions.length
            : answeredQuestions.length + 1
        }}
        ud af {{ questions.length }}
      </p>
      -->
    </div>
    <div
      class="requirement-search-chat__inner"
      :class="{
        'requirement-search-chat__inner--bottom-visible':
          firstQuestion || !isPrioritiesFinished,
      }"
      ref="root"
    >
      <div
        class="requirement-search-chat__dialog-container"
        v-for="answeredQuestion in answeredQuestions"
        :key="answeredQuestion.id"
      >
        <RequirementSearchChatQuestions
          :questions="answeredQuestion.questions"
          :no-animation="true"
        />
        <p
          v-html="getAnswer(answeredQuestion).text"
          class="requirement-search-chat__answer requirement-search-chat__dialog requirement-search-chat__dialog-text requirement-search-chat__dialog-mb"
        ></p>
      </div>
      <RequirementSearchChatQuestions
        v-if="firstQuestion"
        :key="firstQuestion.id"
        :questions="firstQuestion.questions"
      />
      <div v-if="!firstQuestion && !isPrioritiesFinished">
        <RequirementSearchChatQuestion
          :index="0"
          question="
              List disse kriterier efter, hvad du finder vigtigst ved at trække med musen eller klik på pilene.
            "
        />
      </div>
      <div v-if="groups">
        <RequirementSearchChatQuestion
          :index="0"
          question="Tak, for dine svar."
        />
        <div v-if="groups.length > 0">
          <RequirementSearchChatQuestion
            :index="1"
            question="
              Her er de biler, som vi mener, passer bedst til dig.
            "
          />
          <RequirementSearchChatGroups
            :close-chat-callback="closeChatCallback"
            :go-to-bottom-callback="goToBottom"
            :groups="groups"
          />
        </div>
        <div v-else>
          <RequirementSearchChatQuestion
            :index="1"
            question="
              Ud fra dine svar, kan vi desværre ikke finde en bil, der matcher dine behov.
            "
          />
          <button
            @click="restartCallback"
            class="requirement-search-chat__display button button--highlight"
            :class="{ 'requirement-search-chat__display--show': buttonShow }"
          >
            Start forfra
          </button>
        </div>
      </div>
    </div>
    <div class="requirement-search-chat__bottom">
      <div
        v-if="firstQuestion"
        class="requirement-search-chat__answer-container"
        :class="{
          'requirement-search-chat__answer-container--show': questionShow,
        }"
      >
        <button
          v-for="answer in firstQuestion.answers"
          :key="'answer' + answer.value + firstQuestion.id"
          class="requirement-search-chat__answer-action"
          v-html="answer.text"
          @click="chooseAnswer(firstQuestion, answer)"
        ></button>
      </div>
      <div v-else-if="!firstQuestion && !isPrioritiesFinished">
        <RequirementSearchChatPriorities
          :priorities-done-callback="prioritiesDoneCallback"
          :items="priorities"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue'

import RequirementSearchChatQuestions from './RequirementSearchChatQuestions.vue'
import RequirementSearchChatPriorities from './RequirementSearchChatPriorities.vue'
import RequirementSearchChatQuestion from './RequirementSearchChatQuestion.vue'
import RequirementSearchChatGroups from './RequirementSearchChatGroups.vue'

export default {
  components: {
    RequirementSearchChatQuestions,
    RequirementSearchChatPriorities,
    RequirementSearchChatQuestion,
    RequirementSearchChatGroups,
  },
  methods: {
    chooseAnswer(question, answer) {
      // save question and answer
      this.answerCallback({
        questionId: question.id,
        answer: answer,
      })

      this.$nextTick(() => {
        if (this.firstQuestion === undefined) {
          this.questionsDoneCallback()
        }
      })
    },
    getAnswer(question) {
      return question.answers.find(answer => answer.answered)
    },
    goToBottom() {
      nextTick(() => {
        this.$refs.root.scrollTo(0, this.$refs.root.scrollHeight)
      })
    },
    handleShowQuestion() {
      if (this.firstQuestion === undefined) return

      setTimeout(() => {
        this.questionShow = true
      }, this.firstQuestion.questions.length * 1600 * 1.6)
    },
  },
  watch: {
    firstQuestion() {
      nextTick(() => {
        this.questionShow = false
        this.goToBottom()

        this.handleShowQuestion()
      })
    },
    groups() {
      nextTick(() => {
        this.goToBottom()

        if (this.groups && this.groups.length === 0) {
          setTimeout(() => {
            this.buttonShow = true
          }, 2 * 1600 * 1.6) // index 2
        }
      })
    },
    priorities(newValue, oldValue) {
      if (oldValue !== null) return

      nextTick(() => {
        this.goToBottom()
      })
    },
  },
  computed: {
    firstQuestion() {
      return this.questions.find(
        question =>
          question.answers.filter(answer => answer.answered === undefined)
            .length === question.answers.length
      )
    },
    answeredQuestions() {
      return this.questions.filter(
        question =>
          question.answers.find(answer => answer.answered) !== undefined
      )
    },
  },
  data() {
    return {
      questionShow: false,
      buttonShow: false,
    }
  },
  props: {
    questions: {
      default: () => {
        return []
      },
      type: Array,
    },
    groups: {
      default: null,
      type: Array,
    },
    isPrioritiesFinished: { default: false, type: Boolean },
    priorities: {
      default: null,
      type: Array,
    },
    answerCallback: { default: null, type: Function },
    closeChatCallback: { default: null, type: Function },
    restartCallback: { default: null, type: Function },
    questionsDoneCallback: { default: null, type: Function },
    prioritiesDoneCallback: { default: null, type: Function },
  },
  mounted() {
    this.goToBottom()

    this.handleShowQuestion()
  },
}
</script>

<style lang="scss">
@import 'RequirementSearchChat.scss';
</style>
