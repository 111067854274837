<template>
  <div
    class="requirement-search-priorities requirement-search-priorities__display"
    :class="{ 'requirement-search-priorities__display--show': showPriorities }"
  >
    <p class="requirement-search-priorities__title">Vigtigst</p>
    <draggable
      v-model="items"
      item-key="id"
      :animation="200"
      :ghostClass="'ghost'"
    >
      <template #item="{ element, index }">
        <div class="requirement-search-priorities__item">
          <button
            class="requirement-search-priorities__button requirement-search-priorities__button--up"
            :class="{
              'requirement-search-priorities__button--disable': index === 0,
            }"
            @click="moveUp(index)"
          ></button>
          <p
            class="requirement-search-priorities__text"
            v-html="element.title"
          ></p>
          <button
            class="requirement-search-priorities__button requirement-search-priorities__button--down"
            :class="{
              'requirement-search-priorities__button--disable':
                index + 1 === items.length,
            }"
            @click="moveDown(index)"
          ></button>
        </div>
      </template>
    </draggable>
    <p class="requirement-search-priorities__title">Mindst vigtig</p>
    <button
      class="requirement-search-priorities__finish-button button button--highlight"
      @click="prioritiesDoneCallback"
    >
      Ok
    </button>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  methods: {
    moveUp(index) {
      this.move(index, index - 1)
    },
    moveDown(index) {
      this.move(index, index + 1)
    },
    move(from, to) {
      // https://stackoverflow.com/a/7180095/313106
      this.items.splice(from, 0, this.items.splice(to, 1)[0])
    },
  },
  props: {
    items: {
      default: () => {
        return []
      },
      type: Array,
    },
    prioritiesDoneCallback: { default: null, type: Function },
  },
  components: { draggable },
  data() {
    return {
      showPriorities: false,
    }
  },
  mounted() {
    setTimeout(() => {
      this.showPriorities = true
    }, 2 * 1600)
  },
}
</script>

<style lang="scss">
@import 'RequirementSearchChatPriorities.scss';
</style>
