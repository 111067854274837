<template>
  <button
    class="button button--highlight requirement-search__button"
    @click="openChat"
  >
    {{ buttonText }}
  </button>
</template>

<script>
import { app } from '@/main'

export default {
  methods: {
    openChat() {
      app.mitt.emit('open-requirement-chat')
    },
  },
  props: {
    buttonText: { default: 'Start Bilguiden', type: String },
    classNameExtension: { default: '', type: String },
  },
}
</script>
