<template>
  <div
    class="requirement-search-popup requirement-search-popup--campaign"
    :class="{ 'requirement-search-popup--full-size': showPopupFullSize }"
    @click="triggerOpenChat"
  >
    <div class="requirement-search-popup__text-container">
      <h2 class="requirement-search-popup__title">Prøv Bilguiden</h2>
      <p class="requirement-search-popup__text">Find let dit bilmatch</p>
      <img
        class="requirement-search-popup__image-inline-campaign"
        src="/static/img/raster/static/requirement-search/bilguiden-app-fritlagt.png"
      />
      <button class="button button--invert requirement-search-popup__open">
        Start her
      </button>
    </div>
    <div class="requirement-search-popup__image-container">
      <img
        class="requirement-search-popup__image"
        src="@assets/icons/keychain.svg"
      />
      <img
        class="requirement-search-popup__image-campaign"
        src="/static/img/raster/static/requirement-search/bilguiden-app-fritlagt.png"
      />
    </div>
    <button
      class="requirement-search-popup__close"
      @click.stop="triggerCloseChat"
    ></button>
  </div>
</template>

<script>
import { setCookie, removeCookie, getCookie } from '@/functions'

const cookieName = 'RequirementSearchPopupClosed'

export default {
  methods: {
    triggerOpenChat() {
      this.openChatCallback()
      removeCookie(cookieName)
    },
    triggerCloseChat() {
      this.showPopupFullSize = false
      setCookie(cookieName, true, 1)
    },
  },
  data() {
    return {
      showPopupFullSize: this.showPopupFullSizeProp,
    }
  },
  props: {
    showPopupFullSizeProp: { default: true, type: Boolean },
    openChatCallback: { default: null, type: Function },
  },
  created() {
    const requirementSearchPopupClosedCookie = getCookie(cookieName)

    if (requirementSearchPopupClosedCookie) {
      this.showPopupFullSize = false
    }
  },
}
</script>

<style lang="scss">
@import 'RequirementSearchPopup.scss';
</style>
