<template>
  <div>
    <RequirementSearchChatQuestion
      v-for="(question, index) in questions"
      :key="'questions' + index + question.id"
      :question="question"
      :index="index"
      :noAnimation="noAnimation"
    />
  </div>
</template>

<script>
import RequirementSearchChatQuestion from './RequirementSearchChatQuestion.vue'

export default {
  props: {
    questions: {
      default: () => {
        return []
      },
      type: Array,
    },
    noAnimation: { default: false, type: Boolean },
  },
  components: {
    RequirementSearchChatQuestion,
  },
}
</script>
